import PropTypes from 'prop-types';
import useSearchQuery from 'queries/useSearchQuery';
import { useStateContext } from '@activebrands/core-web/contexts/StateContext';
import { useWillUnmount } from '@activebrands/core-web/hooks/lifecycle';
import overlay, { useOverlaySubscriber } from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import { updateQueryParams } from '@activebrands/core-web/utils/query';
import debounce from '@grebban/utils/function/debounce';
import extractQueryParams from '@grebban/utils/string/extractQueryParams';
import { useLocation } from '@reach/router';
import SearchIcon from 'components/icons/SearchIcon';

const Form = styled('form', {
    width: '219px',
    position: 'relative',
    height: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    border: '1px solid var(--color-border-search-header)',
    borderRadius: '50px',
    padding: '4px 0 4px 8px',
    color: 'inherit',
    backgroundColor: 'var(--color-bg-search-header)',
    cursor: 'text',
    transitionDelay: 'var(--header-transition-delay)',
    transitionTimingFunction: 'var(--ease)',
    transitionDuration: 'var(--duration-fast)',
    transitionProperty: 'all',
});

const StyledInput = styled('input', {
    width: '100%',
    padding: '0 8px',
    background: 'none',
    border: 'none',
    color: 'inherit',
    outline: 'none',
    lineHeight: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '::placeholder': {
        color: 'var(--color-text-subtle)',
    },
});

const SearchInput = ({ previewDataOverride, ...rest }) => {
    const [, setState] = useStateContext();
    const location = useLocation();
    const [isSearchOpen] = useOverlaySubscriber(['search']);

    const { headerConfigContent, searchPage } = useSearchQuery();
    const { searchInput } = previewDataOverride || headerConfigContent;

    const handleInputChange = debounce(({ target }) => {
        const query = target.value;
        setState(query);
    }, 500);

    // Navigate to search page and trigger a search there
    const onSubmit = e => {
        e.preventDefault();
        overlay.close('search');
        handleInputChange.cancel();

        let params = {
            q: e.target.elements[0].value,
        };

        // Keep all params except page if the search is performed on the searchpage
        if (location.pathname === searchPage) {
            const queryParams = extractQueryParams(location.search);
            delete queryParams.page;
            params = { ...queryParams, ...params };
        }

        // Update the http-query, this will trigger a search on the search page
        updateQueryParams(params, { basePath: searchPage });
    };

    const setFocus = ({ target }) => {
        let inputEle;

        switch (target.tagName) {
            case 'INPUT':
                inputEle = target;
                overlay.closeAll();
                overlay.open('search', { previewDataOverride });
                break;

            case 'FORM':
                inputEle = target.getElementsByTagName('input')[0];
                break;

            default:
                inputEle = target.closest('form').getElementsByTagName('input')[0];
                break;
        }

        inputEle && inputEle.focus();
    };

    useWillUnmount(() => handleInputChange.cancel());

    return (
        <Form {...rest} $isActive={isSearchOpen} onClick={setFocus} onSubmit={onSubmit}>
            <SearchIcon width="14px" />
            <StyledInput maxLength="100" placeholder={searchInput} onChange={handleInputChange} />
        </Form>
    );
};

SearchInput.propTypes = {
    previewDataOverride: PropTypes.object,
};

export default SearchInput;
